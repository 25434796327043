export type CategoriesKeys =
   | 'online'
   | 'dining-experiences'
   | 'tours-and-attractions'
   | 'adventures-and-activities'
   | 'classes-and-training'
   | 'sports-and-tournaments'
   | 'summer-splash'
   | 'summer-breeze'
   | 'concerts-and-shows'
   | 'cinema-and-theatre'
   | 'exhibitions'
   | 'health-and-fitness'
   | '';

export type Categories = {
   // eslint-disable-next-line no-unused-vars
   [key in CategoriesKeys]?: {
      ar: string;
      en: string;
   } & {
      [key: string]: string;
   };
};

const experiecnesCategories: Categories = {
   online: {
      ar: 'اونلاين',
      en: 'online',
   },
   'dining-experiences': {
      ar: 'تجارب-الطعام',
      en: 'dining-experiences',
   },
   'tours-and-attractions': {
      ar: 'المعالم-السياحية',
      en: 'tours-and-attractions',
   },
   'adventures-and-activities': {
      ar: 'المغامرات-والأنشطة',
      en: 'adventures-and-activities',
   },
   'classes-and-training': {
      ar: 'فعاليات-تعليمية-والتدريب',
      en: 'classes-and-training',
   },
   'sports-and-tournaments': {
      ar: 'فعاليات-رياضية-ومسابقات',
      en: 'sports-and-tournaments',
   },
   'summer-splash': {
      ar: 'جوك-بحر',
      en: 'summer-splash',
   },
   'summer-breeze': {
      ar: 'جوك-جبال',
      en: 'summer-breeze',
   },
   'concerts-and-shows': {
      ar: 'حفلات-والعروض',
      en: 'concerts-and-shows',
   },
   'cinema-and-theatre': {
      ar: 'الأفلام',
      en: 'cinema-and-theatre',
   },
   exhibitions: {
      ar: 'المعارض',
      en: 'exhibitions',
   },
   'health-and-fitness': {
      en: 'health-and-fitness',
      ar: 'الصحة-والرياضة',
   },
};

export default experiecnesCategories;
