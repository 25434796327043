import { SwiperSlide } from 'swiper/react';
import Card from '~/components/common/card';
import { Translate } from '~/i18n';
import { ExperienceProps } from '~/interfaces/experience';
import { visiualCitySlug } from './get-city-slug';
import { isJarir } from './theme';
import cn from 'classnames';
import { StarIcon } from '~/svgs/common';
import { formatNumber } from '~/utils/format-price';
export const getPrice = (price: number, discounted_price: number) => {
   if (discounted_price > 0) return formatNumber(discounted_price) + '';
   return formatNumber(price) + '';
};

export const getDiscount = (price: number, discounted_price: number) => {
   if (price > 0) {
      const amount = (1 - discounted_price / price) * 100;
      return amount.toFixed() + '';
   }
   return '';
};
const getExperienceSlider = (_: string, array: ExperienceProps[] = [], alt?: string) => {
   return array?.map(
      ({
         _id,
         slug,
         name,
         venue_image,
         list_price,
         discounted_price,
         city_slug,
         city,
         category: cat,
         is_external,
         verified,
         region,
         book_online,
         is_dmc,
         optimized_image,
         venue_type,
         venue_type_title,
         type,
         event_is_group,
         is_wishlist,
         rating_avg,
      }) => {
         const isOnline = cat?.slug === 'virtual-experiences';
         const isVerified = verified === 'Yes' ? true : false;

         // ! Quick fix to the issue of sometimes discounted price is bigger than actual price (data entry issue)
         list_price = discounted_price > list_price ? discounted_price : list_price;

         const online = !isOnline ? (
            <span
               className={cn(
                  'block h-auto mb-3 overflow-hidden text-sm truncate',
                  isJarir && 'text-primary-red'
               )}>
               {city}
            </span>
         ) : null;

         const discount = discounted_price > 0 &&
            list_price > 0 &&
            Number(discounted_price) !== Number(list_price) && (
               <div>
                  <span className="text-sm line-through opacity-75 ltr:ml-1 rtl:mr-1">
                     <Translate
                        parseHTML
                        id="common:price"
                        values={{ amount: formatNumber(list_price) + '' }}
                     />
                  </span>
                  <span className="py-0.5 px-1 -mt-1 text-xs text-green-primary font-medium bg-green-primary bg-opacity-10 ltr:ml-2 rtl:mr-2">
                     <Translate
                        id="common:discount"
                        values={{
                           amount: getDiscount(list_price, discounted_price),
                        }}
                     />
                  </span>
               </div>
            );

         return (
            <SwiperSlide className="h-auto py-2" key={_id}>
               <Card
                  is_dmc={is_dmc}
                  category={cat?.title}
                  category_slug={cat?.slug}
                  image={venue_image}
                  title={name}
                  alt={alt}
                  isVerified={isVerified}
                  bookable={book_online === 'Yes'}
                  venueType={venue_type}
                  venueTypeTitle={venue_type_title}
                  optimized_image={optimized_image}
                  href={
                     type === 'events'
                        ? `/${event_is_group === true ? 'tickets/group' : 'tickets'}/${slug}`
                        : `/experiences${visiualCitySlug(region?.slug || city_slug)}/${
                             cat?.slug
                          }/${slug}`
                  }
                  venue_id={_id}
                  is_wishlist={is_wishlist}
                  is_event={type === 'events'}>
                  <div className="flex flex-col flex-grow text-primary-color">
                     <div className="flex-grow">{online}</div>
                     <div className="flex items-center justify-between">
                        {venue_type !== 'offline_experience' && (
                           <div>
                              {discount}
                              <span className="block text-sm font-semibold">
                                 {list_price > 0 || discounted_price > 0 ? (
                                    <Translate
                                       parseHTML
                                       id="common:starts from price"
                                       values={{
                                          start_price: getPrice(list_price, discounted_price),
                                       }}
                                    />
                                 ) : !is_external ? (
                                    <Translate id="common:free" />
                                 ) : null}
                              </span>
                           </div>
                        )}
                        {rating_avg > 0 && (
                           <span className="flex items-center justify-start mt-1 text-sm">
                              <StarIcon className="rtl:ml-1 ltr:mr-1 -mt-1" />
                              <span className="leading-none -mt-0.5">
                                 {formatNumber(rating_avg, 1)}
                              </span>
                           </span>
                        )}
                     </div>
                  </div>
               </Card>
            </SwiperSlide>
         );
      }
   );
};

export default getExperienceSlider;
