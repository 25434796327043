import { FC, useState, useMemo } from 'react';
import { UserType } from '~/interfaces/user';
import { useAuth } from '~/auth';
import classNames from 'classnames';
import styles from './card.module.css';
import { NextLink, useTranslate } from '~/i18n';
import Image from '../image';
import NextImage from 'next/image';
import { ImageWithPlaceholder } from '~/utils/image-plaiceholder';
import experiecnesCategories, { CategoriesKeys } from '~/data/experiences-categories';
import Axios from '~/utils/axios';
import toast from 'react-hot-toast';
import JarirSignup from 'components/jarir/modal';
import { sentryCaptureException } from '~/errors/sentry';
interface HalaCardProps {
   image: string;
   title: string;
   href: string;
   small?: boolean;
   className?: string;
   external?: boolean;
   alt?: string;
   category?: string;
   category_slug?: string;
   isVerified?: boolean;
   venueType?: string;
   venueTypeTitle?: string;
   bookable?: boolean;
   is_dmc?: boolean;
   is_kafu?: boolean;
   optimized_image?: ImageWithPlaceholder;
   venue_id?: string;
   is_wishlist?: boolean;
   is_event?: boolean;
}

const Card: FC<HalaCardProps> = ({
   children,
   href,
   image,
   title,
   alt,
   className,
   small,
   external,
   category,
   isVerified,
   // bookable,
   venueType,
   venueTypeTitle,
   is_dmc,
   is_kafu,
   optimized_image,
   category_slug,
   venue_id,
   is_event,
}) => {
   const Tag = !external ? NextLink : 'a';
   const { authenticated, user, updateUser } = useAuth<UserType>();
   const { translate, locale } = useTranslate();
   const [loading, setLoading] = useState(false);
   const [openJarirSignup, setOpenJarirSignup] = useState(false);

   const isAuthed = authenticated && !!user;

   const updateWishlist = async (is_logged_in?: boolean) => {
      if (!authenticated && !is_logged_in) {
         setOpenJarirSignup(true);
         return;
      }
      if (loading) return;
      setLoading(true);
      try {
         const { data } = await Axios.post('/user/wishlist', {
            venue_id: venue_id,
         });

         if (is_logged_in) {
            window?.location?.reload();
         }

         const is_wishlist =
            data?.message === 'Successfully Removed from wishlist' ||
            user?.wishlist?.includes(venue_id);

         if (data.status === 'Success') {
            setLoading(false);
            toast(
               <span>
                  {is_wishlist
                     ? translate({ id: 'auth:removed from your wishlist' })
                     : translate({ id: 'auth:added to your wishlist' })}
               </span>,
               {
                  icon: is_wishlist ? (
                     <img
                        src="/icons/profile/heart-black-border.png"
                        title={translate({ id: 'auth:add to wishlist' })}
                        className="w-6 mx-0.5 my-0.5"
                     />
                  ) : (
                     <img
                        src="/icons/profile/heart-filled.png"
                        title={translate({ id: 'auth:remove from wishlist' })}
                        className="w-6 mx-0.5 my-0.5"
                     />
                  ),
                  style: {
                     background: '#fef6d6',
                  },
               }
            );
            if (is_wishlist) {
               const _wishlist = user?.wishlist?.filter(item => item !== venue_id);
               updateUser({ ...user, wishlist: _wishlist });
            } else {
               const _wishlist = [...user.wishlist, ...[venue_id]];
               updateUser({ ...user, wishlist: _wishlist });
            }
            if (is_logged_in) {
               window?.location?.reload();
            }
         } else {
            throw new Error(translate({ id: 'business:failed toaster' }));
         }
      } catch (error: any) {
         toast.error(error.message);
         sentryCaptureException(error, { tags: { journey: 'business' } });
      } finally {
         setLoading(false);
      }
   };

   const getCategoryClassNameColor = () => {
      const eng_category = Object.values(experiecnesCategories).find(
         cat => cat[locale] === category_slug
      )?.en;

      switch (eng_category as CategoriesKeys) {
         case 'dining-experiences':
            return 'text-primary-red';
         case 'tours-and-attractions':
         case 'concerts-and-shows':
            return 'text-primary-orange';
         case 'adventures-and-activities':
         case 'cinema-and-theatre':
            return 'text-primary-yellow';
         case 'classes-and-training':
            return 'text-primary-light-blue';
         case 'health-and-fitness':
         case 'sports-and-tournaments':
            return 'text-primary-green';
         case 'summer-splash':
            return 'text-[#06B6D4]';
         case 'summer-breeze':
            return 'text-[#9E3C25]';
         default:
            return 'text-primary-color';
      }
   };

   const getBadge = () => {
      const bookingTypeData: any = {
         internal: {
            image: '/icons/experiences/thunder.svg',
            textColor: '#212529',
            color: '#FFC107',
         },
         external: {
            image: '/icons/experiences/external.svg',
            textColor: '#212529',
            color: '#E3FEFE',
         },
         '24_hours_confirmation': {
            image: '/icons/experiences/24-hours.svg',
            textColor: '#FFFFFF',
            color: '#6610F2',
         },
         online_experience: {
            textColor: '#FFFFFF',
            color: '#FD7E14',
         },
         offline_experience: {
            image: '/icons/experiences/offline.svg',
            textColor: '#FFFFFF',
            color: '#00B9D8',
         },
      };

      if (!venueType || !bookingTypeData[venueType]) return null;
      return (
         <div
            className="flex items-center px-3 py-1"
            style={{
               backgroundColor: bookingTypeData[venueType]?.color,
               color: bookingTypeData[venueType]?.textColor,
            }}>
            {bookingTypeData[venueType]?.image ? (
               <img
                  width="12px"
                  height="10px"
                  alt=""
                  src={bookingTypeData[venueType]?.image}
                  className="ltr:mr-1 rtl:ml-1"
               />
            ) : null}
            <span>{venueTypeTitle}</span>
         </div>
      );
   };

   const favIcon = useMemo(() => {
      const is_wishlist = user?.wishlist?.includes(venue_id);
      return is_wishlist ? (
         <img
            src="/icons/profile/heart-filled.png"
            title={translate({ id: 'auth:remove from wishlist' })}
            className="mx-[3px] my-[2px] pt-0.5"
         />
      ) : (
         <img
            src="/icons/profile/heart.png"
            title={translate({ id: 'auth:add to wishlist' })}
            className="mx-[3px] my-[2px] pt-0.5"
         />
      );
   }, [translate, user, venue_id]);

   return (
      <Tag href={href} className="flex justify-center w-full">
         <div className={classNames(styles['card'], className, { [styles['small']]: small })}>
            {!is_event && !is_kafu ? (
               <div
                  className="absolute top-2 ltr:left-2 rtl:right-2 z-10 rounded-full"
                  style={{ background: 'rgba(52, 58, 64, 0.48)' }}
                  onClick={e => {
                     e.preventDefault();
                     updateWishlist();
                  }}>
                  {favIcon}
               </div>
            ) : null}

            <div className="absolute top-0 z-10 overflow-hidden text-xs ltr:right-0 rtl:left-0 ltr:rounded-bl-default rtl:rounded-br-default">
               {getBadge()}
            </div>
            <div className={styles['img-container']}>
               <Image
                  optimized_image={optimized_image}
                  title={title}
                  src={image}
                  alt={alt || ''}
                  height={small ? 180 : 229}
                  width={425}
                  className={styles['img']}
                  quality={40}
               />
            </div>
            <div className="relative flex flex-col flex-grow px-4 pt-1">
               {is_dmc ? (
                  <div className="absolute transform -translate-y-2/3 -top-2 ltr:right-2 rtl:left-2">
                     <NextImage
                        src="/sta/sta-badge.svg"
                        alt=""
                        width={72}
                        height={72}
                        quality={40}
                     />
                  </div>
               ) : (
                  isVerified && (
                     <div className="absolute transform -translate-y-2/3 -top-2 ltr:right-2 rtl:left-2">
                        <NextImage
                           src="/images/common/certificate.svg"
                           alt=""
                           width={72}
                           height={72}
                           quality={40}
                        />
                     </div>
                  )
               )}

               {category && !is_kafu && (
                  <span
                     className={classNames(
                        getCategoryClassNameColor(),
                        'text-xs uppercase font-bold ltr:tracking-wide'
                     )}>
                     {category}
                  </span>
               )}
               {is_kafu && (
                  <span
                     className={
                        'text-xs uppercase font-medium ltr:tracking-wide text-primary-yellow'
                     }>
                     {category}
                  </span>
               )}
               <div className={styles['title']}>{title}</div>
               {children}
            </div>
         </div>

         {!isAuthed && (
            <JarirSignup
               modalTitle={translate({ id: 'common:login to continue' })}
               open={openJarirSignup}
               onClose={() => {
                  setOpenJarirSignup(false);
                  setLoading(false);
               }}
               openOtp={value => {
                  setOpenJarirSignup(value);
                  setTimeout(() => {
                     updateWishlist(true);
                  }, 500);
               }}
               preventRedirect
            />
         )}
      </Tag>
   );
};

export default Card;
