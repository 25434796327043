export const visiualCitySlug = (city?: string) => {
   let hrefCity = '/';

   switch (city) {
      case 'everywhere':
      case '':
         hrefCity += 'all-cities';
         break;
      case 'mecca':
      case 'makkah':
         hrefCity += 'jeddah';
         break;
      default:
         hrefCity += city;
   }

   return hrefCity;
};

export const getCitySlug = (city: string) => {
   switch (city) {
      case 'all-cities':
         return 'everywhere';
      case 'jeddah':
      case 'makkah':
         return 'mecca';

      default:
         return city;
   }
};
